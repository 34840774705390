import $ from 'jquery';
import { sanitize } from './inc/_sanitize.js';

const $primaryNav = $('#primary-nav');
const $primaryNavList = $($primaryNav).children('ul.primary-nav__list');
const $navItem = $($primaryNavList).children('.js-primary-nav__item');
const $navDiv = $navItem.children('div');
const $langBar = $('.js-language-bar').html();
const $menuName = $primaryNav.attr('data-menu-text');
const $backButtonText = $primaryNav.attr('data-back-text');
const screenBreak = 840;


/* Initial settings-----------------------------------------------------*/
$navItem.removeClass('is-active');
$('.header__container.regular .js-search-bar__form').css('display', 'none');
$navItem.find('.primary-nav__link').attr('aria-expanded', 'false');
$navItem.removeClass('singleActive');
$navDiv.hide();

function updateSearchAriaExpanded() {
    if ($('.js-search-button__link.mobile').hasClass('is-open')) {
        $('.js-search-button__link.mobile').attr('aria-expanded', 'true');
    }
    else {
        $('.js-search-button__link.mobile').attr('aria-expanded', 'false');
    }

    if ($('.js-search-button__link.desktop').hasClass('is-open')) {
        $('.js-search-button__link.desktop').attr('aria-expanded', 'true');
    }
    else {
        $('.js-search-button__link.desktop').attr('aria-expanded', 'false');
    }
}

function updateAriaExpanded() {
    $('li.js-primary-nav__item').each(function () {
        $(this).children().find('.primary-nav__link').attr('aria-expanded', 'false');
    });
    $('li.js-primary-nav__item.is-active').children().find('.primary-nav__link').attr('aria-expanded', 'true');
}

function updateMenuToggleAriaExpanded() {
    if ($('.js-menu-toggle__link').hasClass('is-open')) {
        $('.js-menu-toggle__link').attr('aria-expanded', 'true');
    }
    else {
        $('.js-menu-toggle__link').attr('aria-expanded', 'false');
    }
}

/* Make sure that links without sub links work as regular links--------*/
$('.js-secondary-nav__item').each(function (index, value) {
    if (!$(value).find('.js-tertiary-nav__item').length) {
        $(value).addClass('single');
    }
}); // Check for third level menu


/* Check for items before the nested list and fix alignement if it's off*/
$('.secondary-nav__list').each(function (index, value) {
    if (!$(value).find('span').length) {
        $(value).addClass('empty');
    }
});

/* Set Max-width for nested menu items----------------------------------//
            This is to keep the look of the site consistant.            //
------------------------------------------------------------------------*/
$('.secondary-nav__list-nested').each(function (index, menu) {
    let listCount = 0; // Allow for full width item at bottom
    $(menu).children('.js-secondary-nav__item').children('.js-tertiary-nav__list').each(function (index, value) {

        if ($(value).find('span').length) {
            listCount = listCount + 1;
            if (listCount >= 4) {
                listCount = listCount - 1;
            }
        }
    });// Get the number of same width columns containing items

    $(menu).children('.js-secondary-nav__item').children('.js-tertiary-nav__list').each(function (index, value) {
        let listWidth;
        switch (listCount) {
            case 1:
                listWidth = 'whole';
                break;
            case 2:
                listWidth = 'half';
                break;
            case 3:
                listWidth = 'third';
                break;
            case 4:
                listWidth = 'fourth';
                break;
        }
        $(value).addClass(listWidth);
    }); // Change widths for nested menus
});


$('.js-secondary-nav__container-inner').each(function (index, value) {
    $(value).find('.js-secondary-nav__item').last().css("border-bottom", "0");
}); // Remove last border


/* OBJECT CONTAINING ALL MENU ITEM METHODS------------------------------//
//----------------------------------------------------------------------*/
const menuObj = {

    /* Make sure that the search bar and the dropdown are in the right place*/
    'placeBars': function () {
        const tempHeight = Math.round($('.primary-nav__header').outerHeight());
        $('.js-secondary-nav__container').css('top', tempHeight);
        $('.js-search-bar').css('top', tempHeight);
    },

    'placeMobileBars': function () {
        const tempHeight = Math.round($('.primary-nav__header').outerHeight());
        $('.primary-nav').css('top', tempHeight);
        $('.js-search-bar').css('top', tempHeight);
    },


    /* MOBILE MENU FUNCTIONS--------------------------------------------//
    //        Called like so: menuObj.addClick($(this));                //
    //------------------------------------------------------------------*/
    'addClick': function (x) {
        var $navLink = $(x).parent('span').parent('li');
        if ($(x).hasClass('button-span')) {
            $navLink = $(x).parent().parent('span').parent('li');
        }

        if (!$navLink.hasClass('is-active')) {

            $navLink.addClass('is-active');
            $navLink.siblings().children('div').hide();
            $navLink.siblings('li').removeClass('is-active');
            $navLink.siblings('li').removeClass('singleActive');

            /* -----------------------------------If there is a sub menu*/
            if ($navLink.has("ul").length) {
                $navLink.addClass('is-active');

                if ($navLink.children().fadeIn()) {

                    /* -------------Remove the border from the last item*/
                    $navLink.css('border-bottom', '0px');

                    /* ------------------Check for exisiting back button*/
                    $navLink.parents('.js-primary-nav__list').children('.js-nav__back').hide();
                    $navLink.parents('.js-primary-nav__list').children('.plain').hide();
                    $navLink.siblings('.js-nav__back').remove();
                    $navLink.siblings('.plain').remove();

                    /* -------------------------------Create plain link */
                    if ($(x).hasClass('button-span')) {
                        if (!$(x).parent().hasClass('secondary-nav__link')) {
                            const $newLink = $(x).parent().get(0).outerHTML;
                            $navLink.parent().prepend(`<li class="plain" style="display: block;">${$newLink}</li>`);
                        }
                    }
                    else {
                        if (!$(x).hasClass('secondary-nav__link')) {
                            const $newLink = $(x).get(0).outerHTML;
                            $navLink.parent().prepend(`<li class="plain" style="display: block;">${$newLink}</li>`);
                        }
                    }

                    /* -------------------------------Create back button*/
                    $navLink.parent().prepend(`<li class="js-nav__back nav__back"><a href="#" class="js-nav__back-link nav__back-link"><span><</span> ${$backButtonText}</a></li>`);

                    menuObj.removeLanguageBar();

                    /* ----------------------------Hide other list items*/
                    if ($(x).hasClass('button-span')) {
                        $(x).parent().parent('span').hide();
                    }
                    else {
                        $(x).parent('span').hide();
                    }

                    $navLink.siblings('li').each(function (index, value) {
                        if (!$(value).hasClass('js-nav__back') && !$(value).hasClass('plain')) {
                            $(value).hide();
                        }
                    });

                    $navLink.parent('ul').siblings('ul').each(function (index, value) {
                        $(value).hide();
                    });

                }// If show children


                /* ------------------------------------Back button click//
                //------------------------------------------------------*/
                $('.js-nav__back-link').click(function (e) {
                    $(e.target).parent('li').siblings('li').children('span').fadeIn();
                    $(e.target).parent('li').siblings('li').removeClass('is-active');
                    $(e.target).parent('li').siblings('li').fadeIn();

                    /* -------------------------------- REMOVE PLAIN LINK*/
                    $(e.target).parent('li').siblings('li').remove('.plain');

                    $(e.target).parent('li').siblings('li').parent('ul').siblings('ul').each(function (index, value) {
                        $(value).show();
                    });


                    if ($(e.target).parent('li').parent('ul').hasClass('js-primary-nav__list')) {
                        $(e.target).parent('li').siblings('li').children('div').hide();
                        $(e.target).parent('li').siblings('li').css('border-bottom', '');
                        $(e.target).parent().html($menuName);
                        menuObj.addLanguageBar();
                    } else {
                        $navLink.parents().children('.js-nav__back').fadeIn();
                        $navLink.parents().children('.plain').fadeIn();
                        $(e.target).parent('li').siblings('li').children('div').hide();
                        $(e.target).parent('li').siblings('li').children('ul').hide();
                        $(e.target).parent('li').siblings('li').css('border-bottom', '');
                        $(e.target).parent('li').remove();
                    }
                    updateAriaExpanded();
                });

            } // If there is a child container



        } else { /* -------------------------------IF ITEM IS NOT ACTIVE*/

            $navLink.removeClass('is-active');
            $navLink.removeClass('singleActive');
            $navLink.children('div').hide();
        } // IF ACTIVE

        updateAriaExpanded();
        $('.js-search-bar').removeClass('is-open');
        $('.js-search-button__link').removeClass('is-open');
        updateSearchAriaExpanded();
    },

    /* DESKTOP MENU FUNCTIONS-------------------------------------------//
    //          Called like so: menuObj.addActive($(this));             //
    //------------------------------------------------------------------*/
    'addActive': function (x) {
        if ($(x).hasClass('button-span')) {
            if ($(x).parent().parent().not('.is-active')) {
                $(x).parent().addClass('is-active');
                $(x).parent().siblings().children('div').hide();
                $(x).parent().siblings('li').removeClass('is-active');
                $(x).parent().siblings('li').removeClass('singleActive');
                $(x).parent().children('div').slideDown();
            }

            if ($(x).parent().has("ul").length) {
                $(x).parent().addClass('is-active');
            } else {
                $(x).parent().addClass('singleActive');
            } // If there is a child container
        }
        else {
            if ($(x).not('.is-active')) {
                $(x).addClass('is-active');
                $(x).siblings().children('div').hide();
                $(x).siblings('li').removeClass('is-active');
                $(x).siblings('li').removeClass('singleActive');
                $(x).children('div').slideDown();
            }

            if ($(x).has("ul").length) {
                $(x).addClass('is-active');
            } else {
                $(x).addClass('singleActive');
            }
        }

        updateAriaExpanded();
        $('.js-search-bar').removeClass('is-open');
        $('.js-search-button__link').removeClass('is-open');
        $('.js-search-bar').find('.js-predictive-search__wrapper ').removeClass('is-visible');
        updateSearchAriaExpanded();
    },

    'removeActive': function (x) {
        if ($(x).hasClass('button-span')) {
            if ($(x).parent().hasClass('singleActive')) {
                $(x).parent().removeClass('is-active');
                $(x).parent().removeClass('singleActive');
            }
        }
        else {
            if ($(x).hasClass('singleActive')) {
                $(x).removeClass('is-active');
                $(x).removeClass('singleActive');
            }
        }
        updateAriaExpanded();
    },

    'hideMenu': function (x) {
        if ($(x).hasClass('button-span')) {
            $(x).parent().parent().removeClass('is-active');
            $(x).parent().parent().removeClass('singleActive');
            $(x).parent().hide();
        }
        else {
            $(x).parent().removeClass('is-active');
            $(x).parent().removeClass('singleActive');
            $(x).hide();
        }
        updateAriaExpanded();
    },

    'addBackContainer': function () {
        if (!$('.js-primary-nav__list').children('li').hasClass('js-nav__back')) {
            $('.js-primary-nav__list').prepend(`<li class="js-nav__back nav__back">${$menuName}</li>`);
        }
    },

    'removeBackContainer': function () {
        if ($('.js-primary-nav__list').children('.js-nav__back').html() === $menuName) {
            $('.js-primary-nav__list').children('js-nav__back').remove();
        }
    },

    'addLanguageBar': function () {
        $('#primary-nav').append(`<div class="js-mob-language-bar mob-language-bar">${$langBar}</div>`);
    },

    'removeLanguageBar': function () {
        $('#primary-nav').children('.js-mob-language-bar').remove();
    }
};


/* Initial settings-----------------------------------------------------*/
$navItem.removeClass('is-active');
$navItem.find('.primary-nav__link').attr('aria-expanded', 'false');
$navItem.removeClass('singleActive');
$navDiv.hide();
menuObj.addLanguageBar();


/* Disable null links on desktop----------------------------------------//
(they are necessary on mobile or they wouldn't be links)----------------*/
$('.null-link').each(function (index, value) {
    $(value).click(function (e) {
        if ($(window).width() >= screenBreak) {
            e.preventDefault();
            menuObj.addClick(e.target);
        }
    });
});

/* Click functions only available in mobile nav------------------------*/
$('.js-primary-nav__item').each(function (index, value) { // PRIMARY
    if (!$(value).hasClass('single')) {
        if ($(value).children().first().find('.singlemenuItem').length == 0) {
            $(value).children().children('.primary-nav__link').click(function (e) {
                if ($(window).width() <= screenBreak) {
                    e.preventDefault();
                    menuObj.addClick(e.target);
                }
            });
        }
    }
});

$('.js-secondary-nav__item').each(function (index, value) { // SECONDARY
    if (!$(value).hasClass('single')) {
        $(value).children().children('.secondary-nav__link').click(function (e) {
            if ($(window).width() <= screenBreak) {
                e.preventDefault();
                menuObj.addClick(e.target);
            }
        });
    }
});


/* Hover States--------------------------------------------------------*/
$navItem.mouseenter(function (e) {
    if ($(window).width() >= screenBreak) {
        menuObj.placeBars();
        $('.primary-nav').removeClass('is-open');
        menuObj.addActive($(e.currentTarget));
    }
});

$navItem.mouseleave(function (e) {
    if ($(window).width() >= screenBreak) {
        menuObj.placeBars();
        $('.primary-nav').removeClass('is-open');
        menuObj.removeActive($(e.currentTarget));
    }
});

$navItem.children('div').mouseleave(function (e) {
    if ($(window).width() >= screenBreak) {
        menuObj.placeBars();
        $('.primary-nav').removeClass('is-open');
        menuObj.hideMenu($(e.currentTarget));
    }
});

/*  Desktop menu functions */
$navItem.click(function (e) {
    if (!$(e.target).hasClass('js-close-menu')) {
        if ($(window).width() >= screenBreak) {
            if (!$navItem.hasClass('is-active')) {
                menuObj.placeBars();
                $('.primary-nav').removeClass('is-open');
                menuObj.addActive($(e.currentTarget));
            }
            else {
                menuObj.placeBars();
                $('.primary-nav').removeClass('is-open');
                $(this).find('.secondary-nav__container').hide();
                $(this).removeClass('is-active');
            }
            updateAriaExpanded();
            updateSearchAriaExpanded();
        }
    }
});


/* Resize window--------------------------------------------------------*/
$(window).on('resize', function () {
    if ($(window).width() <= screenBreak) {
        menuObj.placeMobileBars();
        $primaryNav.attr('aria-label', 'MobileNavigation');
        $primaryNav.attr('role', 'dialog');
    } else {
        menuObj.placeBars();
        if ($primaryNav.has('aria-label')) { $primaryNav.removeAttr('aria-label'); }
        if ($primaryNav.has('role')) { $primaryNav.removeAttr('role'); }
    }
});


/* SEARCH BUTTON--------------------------------------------------------*/
$('.js-search-button__link').click(function (e) {
    e.preventDefault();
    if ($(e.target).hasClass('is-open')) {
        $(e.target).removeClass('is-open');
        $('.js-search-bar').removeClass('is-open');
        $('.js-search-bar__form').css('display', 'none');
        $('.js-search-bar').find('.js-predictive-search__wrapper ').removeClass('is-visible');
        $('.js-search-bar__input').attr('tabindex', '-1');
        $('.js-search-bar').find('.submit-button').attr('tabindex', '-1');
        //setup aria label
        $(this).attr('aria-label', $(this).attr('data-search-placeholder'));
    } else {
        const tempHeight = Math.round($('.primary-nav__header').outerHeight());
        $('.js-search-bar').css('top', tempHeight);
        $(e.target).addClass('is-open');
        $('.js-search-bar__form').css('display', 'block');
        $('.js-search-bar').addClass('is-open');
        $('.primary-nav').removeClass('is-open');
        $('.js-menu-toggle__link').removeClass('is-open');
        $('.js-primary-nav__item').removeClass('is-active');
        $('.js-search-bar__input').focus();

        if ($('.js-search-bar').hasClass('is-open')) {
            $('.js-search-bar__input').attr('tabindex', '0');
            $('.js-search-bar').find('.submit-button').attr('tabindex', '0');
        }
        if ($('.js-search-bar').find('.js-predictive-search__wrapper ').length) {
            $('.js-search-bar').find('.js-predictive-search__wrapper ').addClass('is-visible');
        }
        //setup aria label
        $(this).attr('aria-label', $(this).attr('data-close-placeholder'));
        $navDiv.hide();
        $('.primary-nav__link').attr('aria-expanded', 'false');
    }
    updateMenuToggleAriaExpanded();
    updateSearchAriaExpanded();
});


/* MENU TOGGLE----------------------------------------------------------*/
$('.js-menu-toggle__link').click(function (e) {
    if ($(e.target).hasClass('is-open')) {
        $(e.target).removeClass('is-open');
        $('.primary-nav').removeClass('is-open');
    } else {
        $(e.target).addClass('is-open');
        $('.primary-nav').addClass('is-open');
        $('.js-search-button__link').removeClass('is-open');
        $('.js-search-bar').removeClass('is-open');
        $('.js-search-bar').find('.js-predictive-search__wrapper').removeClass('is-visible');

        /* make sure it's in the correct position when it opens */
        if ($(window).width() <= screenBreak) {
            menuObj.placeMobileBars();
        } else {
            menuObj.placeBars();
        }
    }
    updateMenuToggleAriaExpanded();
    updateSearchAriaExpanded();
});

//accessibility
/*
$('#contentsearch').on('keydown', function (e) {
    console.log(e.keyCode)
    if (e.which === 13) {

        setTimeout(function () {
            const tempHeight = Math.round($('.primary-nav__header').outerHeight());
            $('.js-search-bar').css('top', tempHeight);
            $(e.target).addClass('is-open');
            $('.js-search-bar').addClass('is-open');
            $('.js-search-button__link').addClass('is-open');
            $('.primary-nav').removeClass('is-open');
            $('.js-menu-toggle__link').removeClass('is-open');
            $('.js-primary-nav__item').removeClass('is-active');
            document.getElementById('searchterm').focus();
            if ($('.js-search-bar').find('.js-predictive-search__wrapper ').length) {
                $('.js-search-bar').find('.js-predictive-search__wrapper ').addClass('is-visible');
            }

            $navDiv.hide();
        }, 500)
        return false
    }

});
*/
/* SUBMIT SEARCH FORM---------------------------------------------------*/
$('.js-search-bar__form').on('submit', function (e) {
    e.preventDefault();
    const oldUrl = window.location.origin;
    const newAction = $('.js-search-bar__form').attr('action');
    const newQuery = encodeURIComponent($(this).find('.js-search-bar__input').data('name'));
    const newSearch = $(this).find('.js-search-bar__input').val();
    let searchTerm;
    if (newSearch) {
        searchTerm = encodeURIComponent(sanitize(newSearch));
    } else {
        searchTerm = '';
    }
    /* STORE PAGE SIZE & SORT BY VALUE FOR PAGINATION ----------------------------------*/
    localStorage.setItem("SiteSearchPageSize", 9);
    localStorage.setItem("productsSortBy", "ProductName");

    const searchUrl = `${oldUrl}${newAction}?${newQuery}=${searchTerm}`;
    window.location.href = searchUrl;
});

$('.js-search-bar-button__link').click(function (e) {
    e.preventDefault();
    $(e.target).parents('.js-search-bar__form').submit();
});

var list_item = $('.primary-nav__header .primary-nav .primary-nav__list .primary-nav__item');
$(window).keydown(function (e) {
    if (list_item.hasClass('is-active')) {
        if (e.keyCode == 27) {
            list_item.children('div').css('display', 'none');
            list_item.removeClass('is-active');
        }
    }
});

$('a.js-close-menu, button.js-close-menu').click(function (e) {
    const $navLink = $(this).parent().parent().parent().parent('li');
    $($navLink).removeClass('is-active');
    $($navLink).find('.js-secondary-nav__container.secondary-nav__container').hide();
    updateAriaExpanded();
});

/* grid and list view js*/
var searchviewmode = null;
$("a#mode-list").click(function (e) {
    //sessionStorage.setItem("isListView", true);
    //sessionStorage.setItem("isListView", true);
    e.preventDefault();
    searchviewmode = {
        "pageName": location.pathname.split("/").pop(),
        "isListView": true
    };
    sessionStorage.removeItem("searchviewmode");
    sessionStorage.setItem('searchviewmode', JSON.stringify(searchviewmode));
    $("body").removeClass("modes-grid");
    $("body").addClass("modes-list");
    $("a#mode-grid").removeClass("active");
    $("a#mode-list").addClass("active");
    $(".results-target .list-view-material").css("display", "block");
    $(".results-target .grid-view-material").css("display", "none");
});

$("a#mode-grid").click(function (e) {
    //sessionStorage.setItem("isListView", false);
    e.preventDefault();
    searchviewmode = {
        "pageName": location.pathname.split("/").pop(),
        "isListView": false
    };
    sessionStorage.removeItem("searchviewmode");
    sessionStorage.setItem('searchviewmode', JSON.stringify(searchviewmode));
    $("body").removeClass("modes-list");
    $("body").addClass("modes-grid");
    //$("a#mode-grid").removeClass("active");
    $("a#mode-list").removeClass("active");
    $("a#mode-grid").addClass("active");
    $(".results-target .list-view-material").css("display", "none");
    $(".results-target .grid-view-material").css("display", "block");
});

$(document).ready(function () {
    if ($('.modes-mode').length > 0) {
        var searchviewmode = JSON.parse(sessionStorage.getItem("searchviewmode"));
        console.log(searchviewmode);
        if (searchviewmode && (location.href).indexOf(searchviewmode.pageName) >= 0) {

            if (searchviewmode.isListView == true || searchviewmode.isListView == "true") {
                //add class to body here
                $("body").addClass("modes-list");
                $("a#mode-grid").removeClass("active");
                $("a#mode-list").addClass("active");
                $(".results-target .list-view-material").css("display", "block");
                $(".results-target .grid-view-material").css("display", "none");
            }
            else {
                $("body").addClass("modes-grid");
                $("a#mode-list").removeClass("active");
                $("a#mode-grid").addClass("active");
                $(".results-target .list-view-material").css("display", "none");
                $(".results-target .grid-view-material").css("display", "block");
            }
        }
        else {
            if (window.matchMedia("(max-width: 767px)").matches) {
                //add class to body here
                $("body").addClass("modes-list");
                $("a#mode-grid").removeClass("active");
                $("a#mode-list").addClass("active");
                $(".results-target .list-view-material").css("display", "block");
                $(".results-target .grid-view-material").css("display", "none");
            }
            else {
                $("body").addClass("modes-grid");
                $("a#mode-list").removeClass("active");
                $("a#mode-grid").addClass("active");
                $(".results-target .list-view-material").css("display", "none");
                $(".results-target .grid-view-material").css("display", "block");
            }
        }
    }
    
});

/* --------Removed primary link for on click menu will open------*/

/*$("a.primary-nav__link").removeAttr("href").css("cursor", "pointer");*/