/*import "./WattsEU/fancy-box";*/

import "./lib/css-var-polyfill";
import "./top-nav";
import "./video-player";
import "./inc/_how-to-buy-form";
import "./inc/_how-to-buy-cta";
import "./accordion";
import "./scroll-to-top";
import "./brands-carousel";
import "./home-hero-carousel";
import "./highlighter";
import "./predictive-search";

import "./slide-carousel";
import "./google-recaptcha";
import "./cascaded-dropdown";
import "./inc/_filters";
import "./inc/gallery";
import "./inc/selection-tool-connector";
import "./grid-cta";
import "./accessibility-top-nav";
import "./accessibility-predictive-search";
/*watts EU js*/
import "./WattsEU/homebanner";
import "./WattsEU/oem-slider";
import "./WattsEU/header";
import "./WattsEU/newProducts";
import "./WattsEU/slider-progressbar";
import "./WattsEU/datacenter-tabsection-comp";
import "./WattsEU/datacenterslider-comp";
import "./WattsEU/LearnMoreModalWindow";
import "./WattsEU/countryPopupFeedback";
import "./WattsEU/contactUs";
/*import "./WattsEU/heatPump";*/
import "./WattsEU/aboutUs";
import "./WattsEU/banner-image-slider";
import "./WattsEU/downloadAccordion";
import "./WattsEU/case-studies";
import "./WattsEU/downloads-page";
import "./WattsEU/custom-pagination";
import "./WattsEU/products-series";
import "./WattsEU/idroset-configurator-simulator";
import "./WattsEU/common-site";
import "./WattsEU/hiu-calc-ui";
import "./WattsEU/predictive-search";

/*watts EU js ends*/
/*import "./inc/_cookie-control";*/
import "./inc/product-grid-table";
import "./inc/suntouch-calculator/calculator.js";
import "./inc/jump-links.js";
import "./inc/series-jump-links.js";
import "./language";
import "./inc/_hiu-form.js";

var MarkerClusterer = require("node-js-marker-clusterer");

