/* eslint-disable no-unused-vars */
import * as hBars from 'handlebars/dist/handlebars';
import throttle from 'lodash-es/throttle';
import { sanitize } from './inc/_sanitize.js';
import { getSearchHistory } from './inc/search-history.js';
import { getSearchHistoryHTML } from './inc/search-history.js';
import { getSearchHistoryAfterRemoval } from './inc/search-history.js';

const $searchBar = $('.acc-header__container .acc-js-search-bar');

const $wattsSearchHistoryStorageItemName = ($('body').attr('data-website') + "-" + $('.header-serve-history').attr('data-header-name')).replace(/\s+/g, '-').toLowerCase();
const $wattsSearchDestination = ($('.acc-js-search-bar__form').length) ? $('.acc-js-search-bar__form').attr('action') : "/search";

function refreshHistoryEvents(count, sTerm, isLoadOnEmpty) {
    if (count === 1 || count === 2) {
        // Remove handler from existing elements
        $("ul#listHistory .remove").off();

        // Re-add event handler for all matching elements
        $("ul#listHistory .remove").on("click", function () {
            var searchHistoryHTMLAfterRemoval = '';
            var index = $(this).data("index");
            $(this).parent().remove();
            removeSearchHistory(index);

            var lastindex = 0;
            if ($('#listHistory').length > 0) {
                if ($('ul#listHistory li').length >= 1) {
                    lastindex = $('#listHistory li:last-child').index();
                }
            }

            if (lastindex > 0) {
                searchHistoryHTMLAfterRemoval = getSearchHistoryAfterRemoval(sTerm, isLoadOnEmpty, $wattsSearchDestination, $wattsSearchHistoryStorageItemName, "searchterm", lastindex);
            }

            if (searchHistoryHTMLAfterRemoval != '') {
                $("ul#listHistory").append(searchHistoryHTMLAfterRemoval);
                refreshHistoryEvents(2, sTerm, isLoadOnEmpty);
                count = 0;
            }
        });
    }
}


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getSearchHistoryAndRender(sTerm, isLoadOnEmpty) {
    var pssearchHistoryContainer = $(".search-histories");
    var searchHistoryHTML = getSearchHistoryHTML(sTerm, isLoadOnEmpty, $wattsSearchDestination, $wattsSearchHistoryStorageItemName, "searchterm", true, pssearchHistoryContainer.attr('data-title'));
    pssearchHistoryContainer.html("");

    if (searchHistoryHTML != null && searchHistoryHTML.length) {
        pssearchHistoryContainer.show();
        pssearchHistoryContainer.html(searchHistoryHTML);

        refreshHistoryEvents(1, sTerm, isLoadOnEmpty);
    }

}


function removeSearchHistory(index) {
    var searchHistory = getSearchHistory($wattsSearchHistoryStorageItemName);
    searchHistory.splice(index, 1);
    localStorage.setItem($wattsSearchHistoryStorageItemName, JSON.stringify(searchHistory));
}

$searchBar.each(function (index, value) {

    const $searchInput = $(value).find('.acc-js-search-bar__input');
    const $suggestWrapper = $(value).find('.acc-js-predictive-search__wrapper');
    const $searchButton = $(value).parent('.acc-header__container').find('.acc-js-search-button');
    const $searchButtonLink = $searchButton.find('.acc-js-search-button__link');
    const $suggestTarget = $suggestWrapper.children('.acc-js-predictive-search__target');
    const $bssearchSelect = $(value).find('.acc-brand-search-dropdown');
    let brandCookieVal = getSearchBrandCookie("searchBrand");

    if ($bssearchSelect.length > 0) {
        $bssearchSelect.val(brandCookieVal);
    }

    const getSuggestions = throttle(function (typing, brandValue) {
        const $suggestPath = $suggestWrapper.attr('data-endpoint');
        const $resultsLimit = $suggestWrapper.attr('data-resultslimit');
        const $htmlSource = $suggestWrapper.find(".acc-predictive-search__template").html();

        let dataRequested = { searchterm: encodeURIComponent(typing), ResultsLimit: encodeURIComponent($resultsLimit) };
        if (brandValue != '') {
            dataRequested = { searchterm: encodeURIComponent(typing), brand: encodeURIComponent(brandValue), ResultsLimit: encodeURIComponent($resultsLimit) };
        }

        $.ajax({
            type: "GET",
            data: dataRequested,
            url: $suggestPath,
            async: true
        }).done(function (data) {
            if (data && data.TotalResults > 0) {
                const suggestion = data;
                const template = hBars.compile($htmlSource);
                if (suggestion) {
                    const suggested = template(suggestion);
                    $suggestTarget.html(suggested);
                    $suggestWrapper.addClass('is-visible');
                    getSearchHistoryAndRender(typing, false);
                } else {
                    $suggestWrapper.removeClass('is-visible');
                }
            } else {
                $suggestWrapper.removeClass('is-visible');
            }
        }).fail(function () {
            $suggestWrapper.removeClass('is-visible');
        });
    }, 1000);

    $searchInput.keyup(function (event) {
        const newTyping = $(event.currentTarget).val();
        const strLen = newTyping.length;
        let brandval = $(event.target).parent().parent().find('.acc-brand-search-dropdown').val();

        $(".acc-js-secondary-nav__container").hide();
        $(".acc-js-primary-nav__item").removeClass("is-active");

        if (event.which === 13 || event.keyCode === 13) { // Enter key

            // Default form submission
            const newSAction = $('.acc-js-search-bar__form').attr('action');
            const plainUrl = "https://" + window.location.hostname + newSAction + "/";

            let searchQuery = $(event.target).parent().parent().find('.acc-js-search-bar__input').val();
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }

            setSearchBrandCookie(brandval);
            if (brandval != '') {
                let tempBsArray = [];
                tempBsArray.push({ 'Name': "Brand", 'Values': [] });
                tempBsArray[0]['Values'].push(brandval);
                let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));

                window.location.href = `${plainUrl}?searchterm=${searchQuery}&Filters=${filtrs}&Page=0`;
            } else {
                window.location.href = `${plainUrl}?searchterm=${searchQuery}`;
            }

        } else if (strLen > 2) { // Open predictive search when input length > 2
            getSuggestions(sanitize(newTyping), sanitize(brandval));

        } else {
            $suggestTarget.html('');
            $suggestWrapper.removeClass('is-visible');
        }

    });

    $searchInput.on('blur', function () {
        setTimeout(function () {
            if (!document.activeElement.classList.contains('is-focused')) {
                $suggestTarget.html('');
                $suggestWrapper.removeClass('is-visible');
            }
        }, 100);
    });

    $('.acc-js-search-bar__input').click(function (e) {
        if ($(this).val() == "") {
            getSearchHistoryAndRender("", true);
            $suggestWrapper.addClass('is-visible');
            focusFirstSuggestion();
        } else {
            if ($(this).val().length > 2) {
                getSearchHistoryAndRender($(this).val(), false);
                if (!$suggestWrapper.hasClass('is-visible')) {
                    $suggestWrapper.addClass('is-visible');
                    focusFirstSuggestion();
                }
            }
        }
    });

    /* Hiding the auto-suggestions if the user clicks elsewher on the page------*/
    $(document).mouseup(function (e) {
        const $searchWrapperChildren = $suggestWrapper.children();
        if (
            !$suggestWrapper.is(e.target)
            && $searchWrapperChildren.has(e.target).length === 0
            && !$searchButton.is(e.target)
            && !$searchButtonLink.is(e.target)
            && !$searchInput.is(e.target)
            && !$bssearchSelect.is(e.target)
        ) {
            $suggestWrapper.removeClass('is-visible');
            $(value).removeClass('is-open');

            if (!$('.acc-js-search-bar').hasClass('is-open')) {
                $searchButtonLink.removeClass('is-open');
            }
        }
    }); // Hide auto-suggest

    $('.acc-js-search-bar__button .acc-submit-button').click(function (e) {
        e.preventDefault();
        const newSAction = $('.acc-js-search-bar__form').attr('action');
        const plainUrl = "https://" + window.location.hostname + newSAction + "/";

        let brandval = $(this).parent().parent().find('.acc-brand-search-dropdown').val();
        let searchQuery = $(this).parent().parent().find('.acc-js-search-bar__input').val();
        if (searchQuery) {
            searchQuery = sanitize(searchQuery);
        }

        if (brandval != '') {
            let tempBsArray = [];
            tempBsArray.push({ 'Name': "Brand", 'Values': [] });
            tempBsArray[0]['Values'].push(brandval);
            let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));

            setSearchBrandCookie(brandval);

            window.location.href = `${plainUrl}?searchterm=${searchQuery}&Filters=${filtrs}&Page=0`;
        } else {
            window.location.href = `${plainUrl}?searchterm=${searchQuery}`;
        }
    });

    $('.acc-js-search-bar__button .acc-submit-button').keyup(function (e) {
        e.preventDefault();

        if (e.which === 13 || e.keyCode === 13) {
            const newSAction = $('.acc-js-search-bar__form').attr('action');
            const plainUrl = "https://" + window.location.hostname + newSAction + "/";

            let brandval = $(e.target).parent().parent().find('.acc-brand-search-dropdown').val();
            let searchQuery = $(e.target).parent().parent().find('.acc-js-search-bar__input').val();
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }

            if (brandval != '') {
                let tempBsArray = [];
                tempBsArray.push({ 'Name': "Brand", 'Values': [] });
                tempBsArray[0]['Values'].push(brandval);
                let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));
                setSearchBrandCookie(brandval);

                window.location.href = `${plainUrl}?searchterm=${searchQuery}&Filters=${filtrs}&Page=0`;
            } else {
                window.location.href = `${plainUrl}?searchterm=${searchQuery}`;
            }
        }
    });

    function setSearchBrandCookie(brandValue) {
        setCookie("searchBrand", brandValue, 7);
    }

    function getSearchBrandCookie(cname) {
        return getCookie(cname);
    }


    $(document).on('keydown', function (event) {
        const $input = $('.acc-js-search-bar__input');
        const $suggestions = $('.acc-js-predictive-search__link');
        const $histories = $('.search-histories a');
        const $allElements = $histories.add($suggestions);
        let currentFocus = $allElements.index($('.is-focused'));

        if ($(".acc-js-predictive-search__wrapper").hasClass('is-visible')) {
            switch (event.which) {
                case 9: // Tab key
                    $suggestTarget.html('');
                    $suggestWrapper.removeClass('is-visible');
                    $('#acc-brand-search-dropdown').focus();
                    break;
                case 8: // Backspace key
                    if ($input.is(':focus')) {
                        $suggestTarget.html('');
                        $suggestWrapper.removeClass('is-visible');
                    }
                    break;
                case 38: // Up arrow
                    if (currentFocus > 0) {
                        currentFocus--;
                    } else {
                        currentFocus = $allElements.length - 1;
                    }
                    break;
                case 40: // Down arrow
                    if (currentFocus < $allElements.length - 1) {
                        currentFocus++;
                    } else {
                        currentFocus = 0;
                    }
                    break;
                case 27: // Escape key
                    $suggestTarget.html('');
                    $suggestWrapper.removeClass('is-visible');
                    $input.focus();
                    return;
            }

            if (event.which === 38 || event.which === 40) {
                $input.blur(); // Remove focus from the input field
                $allElements.removeClass('is-focused');
                $allElements.eq(currentFocus).addClass('is-focused').focus();
                event.preventDefault();
            }
        }
    });

    function focusFirstSuggestion() {
        const $firstSuggestion = $('.acc-js-predictive-search__link').first();
        const $searchHistorySuggestion = $('.search-histories').find('li').first();
        if ($searchHistorySuggestion.length) {
            $searchHistorySuggestion.addClass('is-focused').focus();
        } else {
            $firstSuggestion.addClass('is-focused').focus();
        }
    }
});


$(window).on('load', function () {

    function highlightCommonCompCom(searchword) {
        if (!$('body').hasClass('wattseu')) {
            if (window.location.href.includes('?searchterm=') || window.location.href.includes('?qh=') || window.location.href.includes('?q=')) {
                if (searchword != "" && $('.main-container').length) {
                    $('.main-container').highlight(searchword.toLowerCase());
                } else {
                    console.log("Higligt not found");
                }
            }
        }
    }
    function highlightSearchTerm() {
        const urlHdrParam = new URLSearchParams(window.location.search);
        let paramHdrValue = urlHdrParam.get('searchterm');

        if (paramHdrValue) {
            paramHdrValue = decodeURIComponent(paramHdrValue);
        }

        setTimeout(function () {
            highlightCommonCompCom(paramHdrValue);
        }, 2000);

    }

    function highlightSearchTermInDetail() {
        const urlHdrParam = new URLSearchParams(window.location.search);
        let paramHdrValue = urlHdrParam.get('qh');

        if (paramHdrValue) {
            paramHdrValue = decodeURIComponent(paramHdrValue);
        }
        setTimeout(function () {
            highlightCommonCompCom(paramHdrValue);
        }, 2000);
    }

    let url = window.location.href;

    if (url.includes("search")) {
        highlightSearchTerm();
    } else {
        highlightSearchTermInDetail();
    }
});

//function openSuggestions() {
//    $(".acc-js-predictive-search__wrapper").addClass('is-visible');
//    const $suggestions = $('.acc-js-predictive-search__link');
//    const $histories = $('.search-histories a');
//    const $allElements = $histories.add($suggestions);
//    if ($allElements.length > 0) {
//        setTimeout(() => {
//            $allElements.first().addClass('is-focused').focus();
//        }, 0);
//    }
//}

//function closeSuggestions() {
//    $(".acc-js-predictive-search__wrapper").removeClass('is-visible');
//    $('.acc-js-predictive-search__link, .search-histories a').removeClass('is-focused');
//    $('.acc-js-predictive-search__target').hide();
//}

//openSuggestions();


//Check if zoom level is 400
function checkZoomLevel() {
    // Calculate the zoom level
    let actualWidth = $('body').width();
    let zoomLevel = (actualWidth / 100) * 100;

    // Check if the zoom level is approximately 400%
    if (zoomLevel >= 320 && zoomLevel <= 410) {
        $('body').addClass("zoom-400");
    } else {
        $('body').removeClass("zoom-400");
    }
}

$(document).ready(function () {
    checkZoomLevel();
});
$(window).resize(function () {
    checkZoomLevel();
});